export const surveyStorageName = "survey-data";
export const surveyAccs = "survey-accs";
export const surveyId = "survey-id";
export const sessionEmail = "session-email";
export const setSessionSurveyData = (surveyData) => {
    const data = surveyData;
    data.pageNo = surveyData.currentPageNo;
    sessionStorage.setItem(surveyStorageName, JSON.stringify(data));
};

export const getSessionSurveyData = () => {
    // console.log("getter: " + sessionStorage.getItem(surveyStorageName))
    return sessionStorage.getItem(surveyStorageName) === null ? false : JSON.parse(sessionStorage.getItem(surveyStorageName));
};

export const clearSessionStorage = () => {
    for (let i = 0; i < sessionStorage.length; i++) {
        const key = sessionStorage.key(i);
        sessionStorage.removeItem(key);
    }
};

export const setSessionStoredAccessKey = (value) => {
    sessionStorage.setItem(surveyAccs, value)
};

export const getSessionStoredAccessKey = () => {
    return sessionStorage.getItem(surveyAccs) === null ? null : sessionStorage.getItem(surveyAccs);
};

export const setSessionStoredID = (value) => {
    sessionStorage.setItem(surveyId, value)
};

export const getSessionStoredID = () => {
    return sessionStorage.getItem(surveyId) === null ? null : sessionStorage.getItem(surveyId);
};


export const setSessionStoredEmail = (value) => {
    sessionStorage.setItem(sessionEmail, value)
};

export const getSessionStoredEmail = () => {
    return sessionStorage.getItem(sessionEmail) === null ? null : sessionStorage.getItem(sessionEmail);
};

export const injectCompleteButtonCss = () => {
    /// Find elements with class 'customComplete' and add an ID attribute
    const elements = document.querySelectorAll(".customComplete");
    if (elements.length > 0) {
        console.log("Element with the class 'customComplete' found.")
        elements.forEach((element) => {
            element.setAttribute('id', 'injectCustomComplete');
        });
    } else {
        console.log("No elements with the class 'customComplete' found.");
    }

}

export const scrollWindowToMiddle = () => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const middlePosition = Math.floor(documentHeight / 2) - Math.floor(windowHeight / 2);
    window.scrollTo({top: middlePosition, behavior: 'smooth'});
};

export const scrollWindowToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
};