import React from 'react';
import styles from '../../styles/ThankYouPage.module.css'
import CheckCircle from '@mui/icons-material/CheckCircle';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

const ThankYouPage = () => {
    return (
        <div className={styles.container}>
            <Paper className={styles.paper}>
                <CheckCircle className={styles.icon}/>
                <Typography variant="h5" className={styles.text}>
                    Köszönjük, hogy kitöltötte kérdőívünket!
                </Typography>
            </Paper>
        </div>
    );
};

export default ThankYouPage;
