import {createTheme} from '@mui/material/styles';

export const dtTheme = createTheme({
    status: {
        danger: '#e53e3e',
    },
    palette: {
        mode: 'light',
        primary: {
            main: '#00408c',
        },
        secondary: {
            main: '#e53e3e',
        },
    },
});