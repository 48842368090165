import React, {useEffect} from "react";
import SurveyComponent from "../components/survey-components/SurveyComponent";
import {useLocation, useParams} from "react-router-dom";
import {scrollWindowToTop} from "../components/utilities/Utils";

export default function ContinueSurvey({
                                           setOpen,
                                           setModalMode,
                                           email,
                                           setEmail,
                                           setPartialInit,
                                           modalSubmitState,
                                           setModalSubmitState,
                                           typeOfSubmit,
                                           sessionFinished,
                                           setSessionFinished,
                                           surveyLoadError,
                                           setSurveyLoadError,
                                           captchaToken,   
                                           setLoading                                        
                                       }) {
    useEffect(() => {
        scrollWindowToTop()
    }, []);

    const {id} = useParams();
    const {search} = useLocation();
    const accessKey = new URLSearchParams(search).get('accessKey');


    // console.log(id)
    // console.log(accessKey)

    return (
        <>
            <SurveyComponent param_id={id} param_accs={accessKey} setOpen={setOpen} email={email} setEmail={setEmail}
                             setModalMode={setModalMode} setPartialInit={setPartialInit}
                             modalSubmitState={modalSubmitState} setModalSubmitState={setModalSubmitState}
                             typeOfSubmit={typeOfSubmit} sessionFinished={sessionFinished}
                             setSessionFinished={setSessionFinished} surveyLoadError={surveyLoadError} setSurveyLoadError={setSurveyLoadError} captchaToken={captchaToken} setSendLoading={setLoading}/>
        </>
    )
}