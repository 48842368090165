import {Box, LinearProgress, Skeleton, Stack, ThemeProvider} from "@mui/material";
import {dtTheme} from "../utilities/colorScheme";

const LoaderElement = () => {
    return (
        <>
            <ThemeProvider theme={dtTheme}>
                <Stack width="100%">
                    {/* For variant="text", adjust the height via font-size */}
                    <Skeleton variant="text" sx={{fontSize: '2rem'}} width="100%"/>
                    {/* For other variants, adjust the size with `width` and `height` */}
                    <Skeleton variant="rounded" width="100%" height={500}/>
                    <Box sx={{width: '100%'}}>
                        <LinearProgress color="primary"/>
                    </Box>
                </Stack>
            </ThemeProvider>
        </>
    );
};

export default LoaderElement;

