import React, { useEffect } from "react";
import SurveyComponent from "../components/survey-components/SurveyComponent";
import { useNavigate } from "react-router-dom";
import { scrollWindowToMiddle, scrollWindowToTop } from "../components/utilities/Utils";

export default function NoInitSurvey({
  setOpen,
  setModalMode,
  email,
  setEmail,
  setPartialInit,
  modalSubmitState,
  setModalSubmitState,
  typeOfSubmit,
  sessionFinished,
  setSessionFinished,
  captchaToken,
  surveyLoadError,
  setSurveyLoadError,
  setLoading
}) {

  const navigate = useNavigate()
  useEffect(()=>{
    if(surveyLoadError){
      scrollWindowToMiddle()
      navigate("/")
    }else{
      scrollWindowToTop()
    }
  },[surveyLoadError, navigate])

  return (
    <div style={{paddingTop: '5rem'}}>
      <SurveyComponent
        setOpen={setOpen}
        email={email}
        setEmail={setEmail}
        setModalMode={setModalMode}
        setPartialInit={setPartialInit}
        modalSubmitState={modalSubmitState}
        setModalSubmitState={setModalSubmitState}
        typeOfSubmit={typeOfSubmit}
        sessionFinished={sessionFinished}
        setSessionFinished={setSessionFinished}
        captchaToken={captchaToken}       
        setSurveyLoadError={setSurveyLoadError}
        setSendLoading={setLoading}
      />
    </div>
  );
}
