import styles from "../../styles/InitSurvey.module.css";
import {useEffect, useState} from "react";
import SurveyComponent from "./SurveyComponent";
import SurveyPageContent from "./SurveyPageContent";
import SurveyLoadErrorMessage from "./SurveyLoadErrorMessage";
import {scrollWindowToMiddle, scrollWindowToTop} from "../utilities/Utils";

const InitSurvey = ({
                        setOpen,
                        setModalMode,
                        email,
                        setEmail,
                        setPartialInit,
                        modalSubmitState,
                        setModalSubmitState,
                        typeOfSubmit,
                        sessionFinished,
                        setSessionFinished,
                        surveyLoadError,
                        setSurveyLoadError,
                        captchaToken,
                        setLoading
                      }) => {
    const [loadSurvey, setLoadSurvey] = useState(false)

    useEffect(() => {
        surveyLoadError ? scrollWindowToMiddle() : scrollWindowToTop()
    }, [surveyLoadError]);

    const handleButtonClick = async () => {
        // // console.log("Init button clicked")
        // console.log("The state of modalSubmitState is: " + modalSubmitState + " BTW..")
        setLoadSurvey(true)
    };
    return (
        <div className={styles.container}>
            {!loadSurvey ? (
                !surveyLoadError ? (
                    <SurveyPageContent action={
                        <>
                            <button
                                type="button"
                                className={styles.clicker}
                                onClick={handleButtonClick}
                            >
                                <span>Kérdőív indítása</span>
                            </button>

                        </>}/>
                ) : (
                    <SurveyPageContent action={
                        <>
                            <SurveyLoadErrorMessage/>
                        </>}/>
                )
            ) : (
                !surveyLoadError ? ( <SurveyComponent setOpen={setOpen} email={email} setEmail={setEmail} setModalMode={setModalMode}
                                                      setPartialInit={setPartialInit} modalSubmitState={modalSubmitState}
                                                      setModalSubmitState={setModalSubmitState} typeOfSubmit={typeOfSubmit}
                                                      sessionFinished={sessionFinished} setSessionFinished={setSessionFinished} setSurveyLoadError={setSurveyLoadError} captchaToken={captchaToken} setSendLoading={setLoading}/>):(
                    <SurveyPageContent action={
                        <>
                            <SurveyLoadErrorMessage/>
                        </>}/>

                )

            )}

        </div>
    );
};

export default InitSurvey;

