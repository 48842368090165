import React, {useEffect} from 'react'
import styles from "../styles/Info.module.css";
import tajekoztato from "../assets/files/palyazati_tajekoztatas.pdf"
import {scrollWindowToTop} from "../components/utilities/Utils";

const Info = () => {
    useEffect(() => {
        scrollWindowToTop()
    }, []);
    return (
        <main className={styles.container}>            
            <p style={{marginTop: "4rem"}}>
                A pályázati tájékoztató megtekinthető és letölthető
                PDF formátumban az alábbi linkre kattintva:
                <a
                    className={styles.download}
                    href={tajekoztato}
                    target='_blank'
                    download={'Pályázati tájékoztatás'}
                    type='download'
                    rel='noopener noreferrer'>
                    letöltés
                </a>
                .
            </p>
            <object data={tajekoztato} type='application/pdf' width='100%' height='1000'
                    aria-label='Application-information'></object>
        </main>
    )
}

export default Info