import styles from "../styles/Footer.module.css";
import img from "../assets/images/DTLogo.svg";
import leanbox from "../assets/images/leanbox.png";
import cariba from "../assets/images/cariba.png";
import qguar from "../assets/images/qguar.png";
import infoblokk from "../assets/images/NKFIA_infoblokk.jpg";
import { Box, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import PlaceIcon from "@mui/icons-material/Place";
import BalanceIcon from "@mui/icons-material/Balance";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const routeChange = (route) => {
    navigate(route);
  };

  const [currentYear, setCurrentYear] = useState();
  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, [setCurrentYear]);

  return (
    <>
      <Grid container className={styles.upperContainer}>
        <Grid xs={12} sm={12} md={4} lg={4}>
          <Box>
            <h2>Kapcsolat</h2>
            <Box>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <EmailIcon className={styles.icons} />
                  </ListItemIcon>
                  <ListItemText primary="info@datatronic.hu" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CallIcon className={styles.icons} />
                  </ListItemIcon>
                  <ListItemText primary="+36 70 392 7242" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <PlaceIcon className={styles.icons} />
                  </ListItemIcon>
                  <ListItemText primary="2142 Nagytarcsa, Csonka János utca 6." />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <BalanceIcon className={styles.icons} />
                  </ListItemIcon>
                  <ListItemText primary="Datatronic Kft." />
                </ListItem>
                <ListItem>
                  <ListItemText
                    style={{ marginLeft: "3.5rem" }}
                    primary="Székhely: 1163 Budapest, Lillafüred utca 10."
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    style={{ marginLeft: "3.5rem" }}
                    primary="Cégjegyzékszám: 01-09-711125"
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    style={{ marginLeft: "3.5rem", marginBottom: "3rem" }}
                    primary="Adószám: 12941076-2-42"
                  />
                </ListItem>
              </List>
            </Box>
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={4} lg={4}>
          <Box>
            <h2>Partnereink</h2>
            <Box>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <a
                      href="https://www.leanbox.hu/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        style={{ background: "whitesmoke", padding: "0.5rem" }}
                        width={"230px"}
                        alt="Leanbox logo"
                        src={leanbox}
                        loading="lazy"
                      />
                    </a>
                  </ListItemIcon>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <a
                      href="https://www.cariba.hu/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        alt="Cariba logo"
                        width={"230px"}
                        src={cariba}
                        loading="lazy"
                      />
                    </a>
                  </ListItemIcon>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <a
                      href="https://quantum-software.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        alt="Qguar logo"
                        width={"230px"}
                        src={qguar}
                        loading="lazy"
                      />
                    </a>
                  </ListItemIcon>
                </ListItem>
              </List>
            </Box>
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={4} lg={4}>
          <Box className={styles.rightContainer}>
            <h2>Projekt</h2>
            <Box sx={{ p: 1.5 }}>
              Ez az oldal a <strong>2020-1.1.1-KKV-START-2021-00474</strong>{" "}
              projekt keretein belül valósulhatott meg.
            </Box>
            <Box>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        routeChange("/info");
                      }}
                      src={infoblokk}
                      width={250}
                      alt="datatronic-logo-navbar"
                      loading="lazy"
                    />
                  </ListItemIcon>
                </ListItem>
              </List>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container className={styles.bottomContainer}>
        <Grid xs={12} sm={4} md={4} lg={4}>
          <Box sx={{ textAlign: "center" }}>
            © Datatronic Kft. {currentYear ? currentYear : ""} | Minden jog
            fenntartva
          </Box>
        </Grid>
        <Grid xs={12} sm={4} md={4} lg={4}>
          <Box sx={{ textAlign: "center" }}>
            <a
              style={{ color: "var(--color-main)" }}
              href="https://datatronic.hu/adatvedelmi_tajekoztato/"
              target="_blank"
              rel="noreferrer"
            >
              Adatkezelési tájékoztató
            </a>
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={4} lg={4}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img
              src={img}
              className={styles.footerLogo}
              width={266}
              height={60}
              alt="datatronic-logo-footer"
              loading="lazy"
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Footer;
