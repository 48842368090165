import React from 'react';
import TextField from '@mui/material/TextField';

function EmailField({setEmail, emailError, setEmailError}) {
    const validateEmail = (value) => {
        return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value);
    };

    const handleChange = (event) => {
        setEmail("")
        let value = event.target.value;
        if (validateEmail(value) && value !== '') {
            setEmailError(false)
            setEmail(value)
        } else {
            setEmailError(true)
        }
    };

    return (
        <TextField
            error={emailError}
            label="E-mail cím"
            helperText={emailError ? 'Hibás E-mail cím' : ''}
            variant="outlined"
            fullWidth
            onChange={handleChange}
            margin="normal"
        />
    );
}

export default EmailField;
