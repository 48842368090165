import "survey-core/defaultV2.min.css";
import "../../styles/SurveyComponent.module.css";
import "survey-core/survey.i18n";
import { Survey } from "survey-react-ui";
import { Model, StylesManager } from "survey-core";
import {
  fetchSurvey,
  fetchSurveyDataFromSave,
  patchCompletedFlag,
  patchSurveyConent,
  sendResult,
} from "../utilities/apiUtils";
import { useCallback, useEffect, useMemo, useState } from "react";
import ThankYouPage from "./ThankYouPage";
import {
  clearSessionStorage,
  getSessionStoredAccessKey,
  getSessionStoredID,
  getSessionSurveyData,
  injectCompleteButtonCss, scrollWindowToTop,
  setSessionStoredAccessKey,
  setSessionStoredEmail,
  setSessionStoredID,
  setSessionSurveyData,
} from "../utilities/Utils";
import LoaderElement from "./LoaderElement";

//survey Model declaration + survey configuration

function SurveyComponent({
  param_id = "",
  param_accs = "",
  setOpen,
  setModalMode,
  email,
  setEmail,
  setPartialInit,
  modalSubmitState,
  setModalSubmitState,
  typeOfSubmit,
  sessionFinished,
  setSessionFinished,
  setSurveyLoadError,
  captchaToken,
  setSendLoading
}) {
  //const {isPartialSaveInitialized} = useStore((state) => ({isPartialSaveInitialized: state.isPartialSaveInitialized}));
  const [surveyContent, setSurveyContent] = useState();
  const [savedSurvey, setSavedSurvey] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    scrollWindowToTop()
  }, []);

  /*eslint-disable*/
  useEffect(() => {
    setLoading(true);
    if (isSaved() === 1) {
      downloadSavedSurvey();
    }
    downloadSurvey().then(() => setLoading(false));
  }, [setLoading]);
  /*eslint-enable*/

  const survey = useMemo(() => {
    const customCss = {
      navigation: {
        complete: "customComplete",
      },
    };

    const temp = new Model(surveyContent);
    temp.addNavigationItem({
      id: "survey_continue_later",
      title: "Befejezés később",
      visibleIndex: 51, // "Complete" button has the visibleIndex 50.
      action: () => {
        // // console.log("navbutton clicked");
        //// // console.log("setting modal mode now");
        //setModalMode(false);
        // // console.log("opening modal now");
        setOpen(true);
      },
    });

    temp.sendResultOnPageNext = true;
    temp.locale = "hu";

    temp.css = customCss;

    // Apply theme
    StylesManager.applyTheme("defaultV2"); //TODO Color theming
    //FIXME Complete button hover color remains basic

    return temp;
  }, [surveyContent, setOpen]);

  //Check if there is a saved unfinished session
  if (isSaved() === 1) {
    survey.data = savedSurvey;
    setSessionStoredAccessKey(param_accs);
    setSessionStoredID(param_id);
  } else if (isSaved() === 2) {
    survey.data = {};
  }

  survey.onPartialSend.add(function (survey) {
    // // // console.log(survey.data)
    if (getSessionStoredAccessKey() === null) {
      //Ha nincs még tárolt session
      // // console.log("I wont patch it haha..");
    } else {
      // // console.log("Patching now..");
      patchSurveyConent(
        //NOTE The content patch bug looks like it is fixed but keep an eye out who knows
        survey.data,
        getSessionStoredAccessKey(),
        getSessionStoredID()
      );
    }
  });

  survey.onComplete.add(function () {
    // // console.log(survey.data);
    setModalMode(true);
    setOpen(true);
  });

  survey.onValueChanged.add(function (survey) {
    setSessionSurveyData(survey.data);
  });

  survey.onAfterRenderPage.add(function () {
    injectCompleteButtonCss();
  });

  const handleModalSubmit = useCallback(async () => {
    switch (typeOfSubmit) {
      case "send": // Kérik az eredményt mehet a küldés. (CompletedFlag)
        // // console.log("SEND branch of handleSubmit")
        // Handle the "send" button submission
        // Perform the necessary actions for sending the form data
        // // console.log("DATA before send: " + survey.data);
        if (getSessionStoredAccessKey() === null) {
          sendResult(captchaToken, survey.data, email, true, true).then(
            (result) => {
              // Pass the return value to the second async function call
              setOpen(false);
              setModalSubmitState(false);
              setPartialInit(false);
              setSessionFinished(true);
              clearSessionStorage();
              setSendLoading(false);
            }
          );
        } else {
          let id = getSessionStoredID();
          let accs_key = getSessionStoredAccessKey();
          patchSurveyConent(getSessionSurveyData(), accs_key, id).then(
            patchCompletedFlag(id, accs_key, captchaToken).then(
              setOpen(false),
              setModalSubmitState(false),
              setPartialInit(false),
              setSessionFinished(true),
              clearSessionStorage,
              setSendLoading(false)
            )
          );
        }
        // // console.log("DATA after send: " + survey.data);
        break;
      case "cancel": // A complete Form-on nem kérték az eredményt (cleanup & no CompletedFlag)
        // // console.log("CANCEL branch of handleSubmit")
        // Handle the "cancel" button submission
        // Perform the necessary actions for canceling the form submission
        // // console.log("DATA before cancel: " + survey.data);
        clearSessionStorage();
        // // console.log("DATA after cancel: " + survey.data);
        setOpen(false);
        setModalSubmitState(false);
        setPartialInit(false);
        setSessionFinished(true);
        break;
      case "save": //Mentés Form-ot adtak be
        // // console.log("SAVE branch of handleSubmit")
        // Handle the "save" button submission
        // Perform the necessary actions for saving the form data
        if (getSessionStoredAccessKey() === null) {
          // Csak akkor kell elküldeni ha nincs még tárolt session..
          if (email && email !== "") {
            // // console.log("DATA before save: " + JSON.stringify(survey.data));
            sendResult(captchaToken, survey.data, email, false).then(
              setModalSubmitState(false),
              setPartialInit(true),
              setOpen(false)
            );
          } else {
            setOpen(false);
            alert("Nem lehet e-mail nélkül elmenteni a kérdőívet!");
            setPartialInit(false);
          }
        } else {
          // // console.log("Partial save already initialized!!!");
        }
        // // console.log("DATA after save: " + JSON.stringify(survey.data));
        break;
      default:
        // Handle any other unexpected button values
        // // console.log("ERROR with the submit type");
        break;
    }
  }, [
    survey.data,
    setModalSubmitState,
    setPartialInit,
    setSessionFinished,
    email,
    typeOfSubmit,
    setOpen,
    captchaToken,
    setSendLoading
  ]);

  useEffect(() => {
    // // console.log("survey.data has changed:", JSON.stringify(survey.data));
    // if(Object.keys(survey.data).length === 0) {
    //     survey.data = !getSessionSurveyData() ? {} : getSessionSurveyData();
    // }
    // Perform any monitoring or debugging actions here
  }, [survey.data]);

  useEffect(() => {
    // // console.log("submit state change detected")
    // // console.log(modalSubmitState)
    if (modalSubmitState) {
      // // console.log("The type of submit the handleMOdalSubmit gets called is: " + typeOfSubmit)
      handleModalSubmit();
    }
  }, [modalSubmitState, handleModalSubmit, typeOfSubmit]);

  async function downloadSurvey() {
    const res = await fetchSurvey();
    if (!res) {
      // // console.log("Error fetching survey")
      setSurveyLoadError(true);
    } else {
      setSurveyContent(res);
    }
  }

  async function downloadSavedSurvey() {
    const res = await fetchSurveyDataFromSave(param_id, param_accs);
    setSavedSurvey(res.content);
    setEmail(res.email);
    setSessionStoredEmail(res.email);
    setPartialInit(true);
  }

  function isSaved() {
    if (param_id !== "" && param_accs !== "") {
      return 1;
    } else if (
      (param_id !== "" && param_accs === "") ||
      (param_id === "" && param_accs !== "")
    ) {
      return 2;
    }
    return 0;
  }

  //Render
  return (
    <>
      {loading && (
        <>
          <LoaderElement />
        </>
      )}
      {!sessionFinished ? (
        <>{surveyContent && <Survey model={survey} />}</>
      ) : (
        <>
          <ThankYouPage />
        </>
      )}
    </>
  );
}

export default SurveyComponent;
