import React, { useEffect } from "react";
import styles from "../styles/Home.module.css";
import FactoryIcon from "@mui/icons-material/Factory";
import GroupsIcon from "@mui/icons-material/Groups";
import MemoryIcon from "@mui/icons-material/Memory";
import GradingIcon from "@mui/icons-material/Grading";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { scrollWindowToTop } from "../components/utilities/Utils";
import SurveyLoadErrorMessage from "../components/survey-components/SurveyLoadErrorMessage";
import { useNavigate } from "react-router-dom";

const Home = ({ surveyLoadError }) => {
  useEffect(() => {
    scrollWindowToTop();
  }, []);

  const navigate = useNavigate();
  return (
    <div className={styles.main}>
      <section className={styles.headerSection}>
        <h1 className={styles.header_Title}>IPAR 4.0 felkészültség</h1>
        <h3 className={styles.header_SubTitle}>
          Online felmérés iparvállalatoknak
        </h3>
        <p className={styles.header_DescMain}>
          Hol tart a vállalkozása az ipari digitalizációban? Határozza meg
          aktuális állapotát az Ipar 4.0 felé vezető úton, és tudja meg, hol
          tart iparági összehasonlításban versenytársaihoz képest!
        </p>
        <p className={styles.header_DescSecondary}>
          Nézze meg, mely területeken van előnyös helyzetben, és megfelelően
          digitalizált állapotban, és mely területeken van lehetőség
          fejlesztésre.
        </p>
        <p>Figyelem! Az Európai Únió által elfogadaott NIS2 irányelv bevezetése, hazánkban is elkezdődött.<br/> Amennyiben az érintett vállalatok nem regisztrálták be magukat <strong>2024 június 30.</strong>-ig, már büntethetőnek minősülnek múlasztás miatt.</p>
        <p style={{ fontSize: "18px"}}><span style={{ color: "var(--color-warn)"}}>Kívánicsi vállalatát érinti-e a NIS2 irányelv? </span><a href="https://nis2.datatronic.hu" target="_blank" rel="noreferrer" style={{ color: "var(--color-main)" }}><strong>Derítse ki, pár perc az egész!</strong></a></p>
      </section>
      {!surveyLoadError ? (
        <button
          type="button"
          className={styles.clicker}
          onClick={() => {
            navigate("/noinit-survey");
          }}
        >
          <span>Kérdőív indítása</span>
        </button>
      ) : (
        <SurveyLoadErrorMessage />
      )}
      <section className={styles.graphSection}>
        <div className={styles.graph_1}></div>

        <div className={styles.graph_2}></div>
        <p className={styles.graphDescription}>
          Az ábrán látható Ipar 4.0 modell képezi a felmérés és az
          összehasonlítás alapját. Ezeknek a részterületeknek a vizsgálatára
          kifejlesztett online felmérés lehetőséget kínál a cégeknek arra, hogy
          önállóan határozzák meg Ipar 4.0 felkészültségüket, mielőtt bármilyen
          további beruházásba kezdenének.
        </p>
      </section>
      <section className={styles.headerSection2}>
        <h1 className={styles.header_Title}>A tesztelési modell</h1>
        <p className={styles.header_DescMain}>
          Jelen Ipar 4.0 audit alkalmazás kiértékelési modelljének alapját az
          Ipar 4.0 általánosan elfogadott 6 alapvető dimenziójának vizsgálata
          adja. Ezek a következők:
        </p>
      </section>

      <section className={styles.graphSection2}>
        <div className={styles.cardContainer}>
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              <GradingIcon className={styles.icon} />
              <p>Stratégia</p>
            </div>
            <div className={styles.cardDesc}>
              <p>
                A vállalat digitális átállása pontosan leírt célokat, üzleti
                modellt és folyamatokat, valamint fejlesztési tervet követel.
              </p>
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              <FactoryIcon className={styles.icon} />
              <p>Okos gyár</p>
            </div>
            <div className={styles.cardDesc}>
              <p>
                A sikeres ipar 4.0-ás átállás után a gyár decentralizálódik és
                nagymértékben automatikusan működik.
              </p>
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              <AccountTreeIcon className={styles.icon} />
              <p>Okos működés</p>
            </div>
            <div className={styles.cardDesc}>
              <p>
                A gyártási folyamatok során keletkező adathalmaz digitálisan
                kerül rögzítésre és kiértékelésre, integráltan a vállalat ERP
                rendszerével.
              </p>
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              <MemoryIcon className={styles.icon} />
              <p>Okos termékek</p>
            </div>
            <div className={styles.cardDesc}>
              <p>
                Ipar 4.0 rendszerben a termékekkel kapcsolatos dokumentációk,
                tervezési adatok és folyamatok is digitális formában valósulnak
                meg.
              </p>
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              <QueryStatsIcon className={styles.icon} />
              <p>Adatvezérelt megoldások</p>
            </div>
            <div className={styles.cardDesc}>
              <p>
                Az adatok alapján való döntéshozatal és folyamatvezérlés az
                üzleti modell részévé válik.
              </p>
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              <GroupsIcon className={styles.icon} />
              <p>Alkalmazottak</p>
            </div>
            <div className={styles.cardDesc}>
              <p>
                A vállalat szervezetének egésze felkészült a digitális eszközök,
                szoftverek használatára és az új folyamatok alkalmazására.
              </p>
            </div>
          </div>
        </div>
        <p className={styles.graphDescription}>
          Ebből a hat dimenzióból egy ötlépcsős modellt dolgoztunk ki az Ipar
          4.0 felkészültség mérésére. Az 5 készültségi szint (0-tól 4-ig)
          mindegyike tartalmaz meghatározott minimumkövetelményeket, amelyek
          nélkül egy szintet sem lehet teljesítettnek tekinteni. A 0. szinten
          azok az érdeklődők állnak, akik még nem, vagy csak kis mértékben
          végezték el az Ipar 4.0 intézkedéseinek tervezését vagy végrehajtását.
          A 4. szint az igazán komplex digitalizációval rendelkező vállalatokat
          írja le, vagyis azokat, amelyek sikeresen végrehajtották az összes
          Ipar 4.0 előírást.
        </p>
      </section>
      <section className={styles.description}>
        <h2 className={styles.descriptionTitle}>Stratégia</h2>
        <p>
          Mivel az Ipar 4.0 nemcsak a meglévő termékek vagy folyamatok,
          digitális technológiák felhasználásával történő fejlesztését jelenti,
          hanem lehetőséget kínál teljesen új üzleti modellek kidolgozására is,
          a megvalósítás stratégiai jelentőséggel bír. Az Ipar 4.0 kezelésének
          nyitottságát és kultúráját jelenleg a következő négy kritérium alapján
          vizsgálják:
        </p>
        <ul>
          <li className={styles.listItem}>
            Az Ipar 4.0 stratégia végrehajtási állapota
          </li>
          <li className={styles.listItem}>
            A stratégia operacionalizálása és felülvizsgálata kulcsmérőszámok
            rendszerével
          </li>
          <li className={styles.listItem}>
            Ipar 4.0-hoz kapcsolódó beruházási tevékenység
          </li>
          <li className={styles.listItem}>
            Technológia alkalmazása és innovációmenedzsment
          </li>
        </ul>
        <h2 className={styles.descriptionTitle}>Okos gyár</h2>
        <p>
          Az Ipar 4.0 sikeres bevezetésének feltétele, hogy a gyár folyamatai és
          berendezései készek legyenek a digitális adatszolgáltatásra és
          adatfogadásra. Az Okosgyárban a gépek, berendezések alapvetően
          digitális vezérlésűek, digitális adatvételi lehetőséggel vagy
          szenzorozással.
        </p>
        <p>
          Ide tartozik a termékek, hordozók, logisztikai eszközök digitális
          azonosítása, pl. RFID vagy vonalkódos azonosítás. Ez az alapja a
          könnyű azonosításnak, kezelhetőségnek, könyvelések automatizmusának,
          illetve a nyomon követésnek.
        </p>
        <p>
          A fejlett Okosgyár magába foglalja nem csak a közvetlenül a
          gyártáshoz, termékelőállításhoz kapcsolódó berendezéseket, hanem a
          támogató folyamatok entitásait is, mint például a logisztika,
          karbantartás, mérnökség vagy minőségügy.
        </p>
        <p>
          A fejlett Okosgyár rendelkezik a megfelelő, logikákkal és
          intelligenciával kiegészített, akár automatikus intézkedésre képes
          kommunikációs rendszerrel, eszközökkel. Ezek a rendszerek, eszközök
          képesek hatékony és gyors, kevés erőforrást igénylő kommunikációra,
          utasítás kiadásra vagy feladatkiosztásra.
        </p>
        <p>
          Az intelligens gyár koncepciója így hatékonyabb
          információszolgáltatást és erőforrás-felhasználást biztosít. A
          termelési létesítményeknek, az információs rendszereknek és az
          embereknek valós időben kell együttműködniük a vállalat keretein kívül
          is. Ezek a hálózati rendszerek nagy mennyiségű adatot generálnak,
          amelyeket feldolgoznak, elemeznek és döntési modellekbe integrálnak.
        </p>
        <p>
          Annak meghatározásához, hogy egy vállalat mennyire előrehaladott az
          Okos gyár dimenzióban, a következő négy témaköröket vizsgáljuk meg:
        </p>
        <ul>
          <li className={styles.listItem}>Digitalizációs fejlettség</li>
          <li className={styles.listItem}>Géppark digitalizáció szintje</li>
          <li className={styles.listItem}>Adathasználat</li>
          <li className={styles.listItem}>Informatikai rendszerek</li>
        </ul>
        <h2 className={styles.descriptionTitle}>Okos működés</h2>
        <p>
          Az Ipar 4.0 központi jellemzője a fizikai és virtuális világ
          vállalaton belüli és vállalatközi hálózatba szervezése. A
          digitalizáció megjelenésével és az ezzel járó adatok elérhetővé
          válásával a termelési és logisztikai környezetben a termeléstervezés
          és irányítás (PPS) és az ellátási lánc menedzsment (SCM) teljesen új
          formái és megközelítései valósulhatnak meg. Az Okos működés tehát
          magában foglalja a műszaki követelményeket a gyártásban és a
          gyártástervezésben, egészen az ún. „önvezérelt munkadarab”
          megvalósításáig.
        </p>
        <p>
          A fejlett Okos működés az alapja a sikeres digitalizációnak.
          Fejlesztése során a fókusz a folyamatok hatékonyabbá tételén van,
          valamint a standardizáció kialakításán, amelyek könnyebbé teszik a
          digitális leképezést.
        </p>
        <p>
          Az Ipar 4.0-ra való felkészültséget az Okos működés dimenzióhoz a
          következő kritériumok alapján határozzuk meg:
        </p>
        <ul>
          <li className={styles.listItem}>Folyamatok definiáltsága</li>
          <li className={styles.listItem}>Folyamatok dokumentáltsága</li>
          <li className={styles.listItem}>Standardizációs szint</li>
          <li className={styles.listItem}>Fejlesztések folyamata</li>
        </ul>
        <h2 className={styles.descriptionTitle}>Okos termékek</h2>
        <p>
          A hatékony tervezési, fejlesztési feladatok elvégzéséhez és a
          terméktervezéshez szükség van digitális megoldásokra.
        </p>
        <p>
          A tervezési megoldások digitalizációja, a terv- és termékdokumentációk
          digitális kezelése az alapja egy agilis, rugalmas és jól nyomon
          követhető digitális környezetnek.
        </p>
        <p>
          A digitális tervezés vagy dokumentáció sokkal hatékonyabbá teszi a
          gyár adminisztratív munkáját.
        </p>
        <p>
          A digitális design vagy akár az erre épülő szimulációs megoldások nem
          csak hatékonyabb tervezési munkát eredményeznek, de jelentősen
          csökkentik a ráfordított időt és a költségeket is. A digitálisan
          tervezett és szimulált termékek, komponensek nem csak önmagukban
          elemezhetőek, hanem akár a későbbi beépítési környezetben is,
          vizsgálva az illeszkedést és szerelhetőséget.
        </p>
        <p>
          Illetve magasabb szintű szimulációnál komplex funkcionális tesztek is
          elvégezhetőek. Ezzel együtt digitálisan tervezhető és szimulálható a
          termékek gyártási folyamata és gyártástechnológiája is.
        </p>
        <h2 className={styles.descriptionTitle}>Adatvezérelt megoldások</h2>
        <p>
          Az adatvezérelt megoldások dimenziójának a célja a gyártási folyamat
          során keletkező adatok minél inkább automatizált gyűjtése,
          kiértékelése és a teljesen automatikus folyamatirányítás
          megvalósítása.
        </p>
        <p>
          Az adatvezérelt megoldások lényege az aktív adathasználat. A gyűjtött
          adatokat, megfelelő környezetbe és kontextusba helyezve, hasznos
          információkat kapunk a folyamatról.
        </p>
        <p>
          Ezen adatok folyamatos figyelésével, minták keresésével
          felfedezhetőek olyan anomáliák, amelyekből eseményeket tudunk
          generálni. Az adott eseményekre a rendszer automatikusan, emberi
          beavatkozás nélkül is képes intézkedéseket, figyelmeztetéseket,
          eszkalációt definiálni és végrehajtatni.
        </p>
        <p>
          A teszt a következő kritériumokat vizsgálja az adatvezérelt megoldások
          területén való felkészültség meghatározásához:
        </p>
        <ul>
          <li className={styles.listItem}>
            Rendelkezésre álló üzemi adatok köre
          </li>
          <li className={styles.listItem}>
            Az adatgyűjtés módja, digitalizációs szintje
          </li>
          <li className={styles.listItem}>
            Az adathasználat és konverzió szintje
          </li>
          <li className={styles.listItem}>
            Az automatizált figyelmeztetések, intézkedések és folyamatok
            kidolgozottsága
          </li>
        </ul>
        <h2 className={styles.descriptionTitle}>Munkatársak és szervezet</h2>
        <p>
          Az alkalmazottak hozzájárulnak a vállalat digitális átalakulásához és
          jelentősen érintik őket a digitális munka világában bekövetkező
          változások. Számukra megváltozik a közvetlen munkahelyi környezet, ami
          új készségeket és képesítéseket igényel. Ezért egyre fontosabb, hogy a
          vállalatok megfelelő képzési és továbbképzési intézkedésekkel
          felkészítsék alkalmazottaikat a digitális átalakulásra.
        </p>
        <p>
          A munkavállalói dimenzióban a felkészültség meghatározásához a
          munkavállalói kompetenciákat, valamint a vállalatok
          kompetenciafejlesztési törekvéseit értékeljük.
        </p>
      </section>
    </div>
  );
};

export default Home;
