import styles from "../../styles/InitSurvey.module.css";
import logo from "../../assets/images/logo.svg";

const SurveyPageContent = ({action}) => {
    return (
        <>
            <section className={styles.headerSection}>
                <h2 className={styles.header_Title}>Online teszt cégeknek</h2>
                <p className={styles.header_DescSecondary}>
                    A felmérés során a vállalat folyamataival és a digitalizációs
                    állapotával, értettségével kapcsolatos kérdéseket teszünk fel. A
                    válaszadás körülbelül 20 percet vesz igénybe, és menet közben
                    bármikor megszakítható, majd később folytatható. A teszt teljes
                    kitöltését követően azonnal megkapja a teszteredményeit és a
                    személyre szóló tanácsokat, javaslatokat tartalmazó kiértékelő
                    dokumentumot.
                </p>
            </section>
            <section className={styles.description}>
                <h2 className={styles.descriptionTitle}>A koncepció</h2>
                <p>
                    Az önellenőrzés az Ipar 4.0-ra vonatkozó hat dimenzión alapul. A
                    hat dimenzió mindegyike egy-egy kérdéscsoportnak felel meg a
                    tesztben, mely a következőket vizsgálja:
                </p>
                <p style={{marginLeft: "3rem"}}>
                    <b className={styles.descriptionSubTitle}>Stratégia:</b> Az Ipar
                    4.0 mennyire van jelen, illetve mennyire van implementálva az Ön
                    vállalati stratégiájában?
                </p>
                <p style={{marginLeft: "3rem"}}>
                    <b className={styles.descriptionSubTitle}>Okos gyár:</b> Ön
                    cégében milyen mértékben vannak digitálisan hálózatba kötött
                    gépek, berendezések, milyen digitális megoldásokat használnak az
                    egyes területeken és milyen mértékű az automatizált, rendszereken
                    alapuló gyártás?
                </p>
                <p style={{marginLeft: "3rem"}}>
                    <b className={styles.descriptionSubTitle}>Okos működés:</b>{" "}
                    Mennyire szabályozottak, standardizáltak a folyamatok az Ön
                    cégében, és milyen mértékben képezhetőek le egy digitális
                    megoldásban, vezérelhetők-e egy virtuális világban, digitális
                    rendszerek és algoritmusok segítségével?
                </p>
                <p style={{marginLeft: "3rem"}}>
                    <b className={styles.descriptionSubTitle}>Okos termékek:</b>{" "}
                    Mennyire alkalmazza a különböző digitális tervező és szimulációs
                    lehetőségeket a termék tervezés során?
                </p>
                <p style={{marginLeft: "3rem"}}>
                    <b className={styles.descriptionSubTitle}>
                        Adatvezérelt megoldások:
                    </b>{" "}
                    Mennyire hatja át a vállalat működését és a napi döntéshozatalt az
                    adatközpontúság? Vannak-e eseményeken, adatokon alapuló
                    figyelmeztetések, automatizált folyamatok? Hogy áll a
                    folyamatfelügyelet?
                </p>
                <p style={{marginLeft: "3rem"}}>
                    <b className={styles.descriptionSubTitle}>
                        Szervezet és alkalmazottak:
                    </b>{" "}
                    Az Ön cégében rendelkezésre álló személyi kompetenciák elegendőek
                    az Ipar 4.0 szerinti munkavégzéshez? Milyen szervezeti
                    változásokat igényel a digitalizáció?
                </p>
                <h2 className={styles.descriptionTitle}>Értékelés</h2>
                <p>
                    Ön választja ki azokat a kritériumokat, amelyek alapján össze
                    szeretné hasonlítani magát más vállalatokkal az Ipar 4.0
                    vonatkozásában. Az iparági hovatartozás mellett további
                    összehasonlítási szempontként kiválaszthatja a vállalat méretét.
                </p>
                <p>
                    <b className={styles.descriptionSubTitle}>Megjegyzés:</b> A
                    hasonló vállalatoktól származó információkat 2023-tól kezdődően
                    gyűjti a rendszer, minden egyes sikeres tesztkitöltés során.
                </p>
                <p>
                    A kérdőív kitöltése után megtekintheti egyéni értékelését, melyet
                    PDF formátumban emailben is elküldünk Önnek. Eredményei alapján
                    megnevezzük azokat az intézkedéseket, amelyek szükségesek ahhoz,
                    hogy cége magasabb szintű Ipar 4.0 készültséget érjen el.
                </p>{" "}
                <p>
                    Kérjük vegye figyelembe, hogy bár a kiértékelés kellően részletes
                    és iparág, valamint cégméret függő is, a meghatározott javaslatok,
                    tanácsok még így is csak általános iránymutatásnak tekinthetők,
                    semmi esetre sem jelentenek azonnal megvalósítható konkrét
                    digitális megoldást az adott szervezetre, cégre vagy gyárra
                    szabva! Ehhez szükség van további helyszíni egyeztetésre, hogy a
                    jelen ipar 4.0 audit eredményét felhasználva és azt pontosítva egy
                    valóban <b> részletes és cégre szabott megoldás portfoliót </b>
                    tudjunk javasolni. Ez minimum egy konkrét konzultációs napot
                    jelent. <br></br> Egy ilyen nap után a Datatronic Kft.
                </p>
                <ul>
                    <li className={styles.listItem}>
                        konkrét digitális megoldásokra tud javaslatot tenni,
                    </li>
                    <li className={styles.listItem}>
                        sorrendet és prioritásokat tud meghatározni és
                    </li>
                    <li className={styles.listItem}>
                        meg tudja mutatni, hogy az adott gyárnak / cégnek milyen
                        előnyöket hoz a digitalizáció.
                    </li>
                </ul>
                {action}
                <div className={styles.descriptionContainer}>
                    <h2>Felhasználási feltételek és adatvédelmi szabályzat</h2>
                    <p>Az önellenőrzés használata ingyenes.</p>
                    <p>
                        Az önellenőrzés során nem rögzítünk személyes adatokat és
                        cégneveket, kizárólag egy emailcím kerül elmentésre az eredmény
                        dokumentum kiküldéséhez, ill. a félbehagyott kérdőívek
                        folytatásához. Az Ön által megadott adatokat a Datatronic Kft
                        szerverein tároljuk, és kizárólag statisztikai célokra
                        használjuk fel. Az adatok harmadik fél részére <b>NEM</b> kerülnek
                        továbbításra.
                    </p>
                    <p>
                        Ha további kérdése, észrevétele van az önellenőrzéssel
                        kapcsolatban, kérjük forduljon a Datatronic Kft-hez. Örömmel
                        támogatjuk Önt saját IT csapatunkkal és szakmai partnereinkkel
                        együtt, és szívesen fogadjuk visszajelzéseit!
                    </p>
                    <div className={styles.descSummary}>
                        <a
                            href="https://datatronic.hu"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src={logo}
                                width={226}
                                height={60}
                                alt="datatronic-logo-navbar"
                            />
                        </a>
                        <p>+36 70 392 7242</p>
                        <a href="mailto:info@datatronic.hu">info@datatronic.hu</a>
                        <a
                            href="https://datatronic.hu"
                            target="_blank"
                            rel="noreferrer"
                        >
                            www.datatronic.hu
                        </a>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SurveyPageContent;

