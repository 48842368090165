import React from 'react';
import {Checkbox, FormControlLabel, Link, Typography} from '@mui/material';

const PrivacyPolicyCheckbox = ({setPrivacyAcceptSate, privacyErrorMessage, setPrivacyErrorMessage}) => {


    const handleChange = (event) => {
        setPrivacyAcceptSate(event.target.checked);
        event.target.checked ? setPrivacyErrorMessage('') : setPrivacyErrorMessage('*Kérjük fogadja el az adatvédelmi nyilatkozatot!');
    };

    return (
        <>
            <FormControlLabel
                control={<Checkbox onChange={handleChange}/>}
                label={
                    <>
                        Elfogadom az{' '}
                        <Link
                            href="https://datatronic.hu/adatvedelmi_tajekoztato/"
                            target="_blank"
                            rel="noopener noreferrer"
                            underline="hover"
                        >
                            Adatvédelmi nyilatkozatot
                        </Link>
                        .
                    </>
                }
            />
            <Typography variant="caption" color="error">
                {privacyErrorMessage}
            </Typography>

        </>
    );
};

export default PrivacyPolicyCheckbox;
