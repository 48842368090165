import { Modal } from "react-responsive-modal";
import styles from "../styles/App.module.css";
import { Button, CircularProgress, createTheme, Grid } from "@mui/material";
import EmailField from "../components/modal-components/EmailField";
import React, { useRef, useState } from "react";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import PrivacyPolicyCheckbox from "../components/modal-components/PrivacyPolicyCheckbox";
import ReCAPTCHA from "react-google-recaptcha";
import ENV from "../env";

createTheme({
  palette: {
    i4_ui: {
      main: "#00408CFF",
      dark: "#002c62",
      contrastText: "#fff",
    },
  },
});

export default function SurveyModal({
  open,
  setOpen,
  isCompleteModalNeeded,
  isPartialSaveInitialized,
  email,
  setEmail,
  setModalSubmitState,
  setTypeOfSubmit,
  privacyAcceptState,
  setPrivacyAcceptSate,
  setCaptchaToken,
  loading,
  setLoading,
}) {
  const [emailError, setEmailError] = useState(false);

  const emailFieldProps = {
    setEmail,
    emailError,
    setEmailError,
  };

  const [privacyErrorMessage, setPrivacyErrorMessage] = useState("");
  const privacyCheckboxProps = {
    setPrivacyAcceptSate,
    privacyErrorMessage,
    setPrivacyErrorMessage,
  };
  const handleSubmit = (caller) => {
    if (caller === "send") {
      setLoading(true);
    }
    setCaptchaToken(captchaRef?.current?.getValue());
    captchaRef.current.reset();
    !privacyAcceptState
      ? setPrivacyErrorMessage(
          "*Kérjük fogadja el az adatvédelmi nyilatkozatot!"
        )
      : setPrivacyErrorMessage("");
    //// console.log("Partial Save Form Submitted")
    //// console.log("The Caller is a:" + caller)
    setTypeOfSubmit(caller);
    setModalSubmitState(true); //Calling of submit in I4Survey
  };
  //TODO Color theming https://zenoo.github.io/mui-theme-creator/

  const captchaRef = useRef();

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)} center>
        {isCompleteModalNeeded ? (
          <>
            {isPartialSaveInitialized ? (
              <>
                <h2>
                  Jelenlegi e-mail címe: <strong>{email}</strong>
                </h2>
                <h4>Kéri az eredményt? (email-t küldünk)</h4>
                <Grid container spacing={2}>
                  <Grid item>
                    <ReCAPTCHA
                      sitekey={ENV.REACT_APP_RECAPTCHA_SITE_KEY}
                      ref={captchaRef}
                    />
                  </Grid>{" "}
                  <Grid item>
                    <Button
                      className={styles.modalStyling}
                      onClick={() => {
                        handleSubmit("send");
                      }}
                      color="primary"
                      variant="contained"
                      name="submitButton"
                      value="send"
                      type="button"
                      // disabled={!captchaToken}
                    >
                      {loading ? <CircularProgress size={24} /> : "Küldés"}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() => {
                        handleSubmit("cancel");
                      }}
                      color="error"
                      variant="contained"
                      name="submitButton"
                      value="cancel"
                      type="button"
                    >
                      Nem kérem
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <h2>
                  Az eredmény küldéséhez, kérjük adja meg az e-mail címét!
                </h2>

                <EmailField {...emailFieldProps} />
                <Grid container spacing={2}>
                  <Grid item>
                    <ReCAPTCHA
                      sitekey={ENV.REACT_APP_RECAPTCHA_SITE_KEY}
                      ref={captchaRef}
                    />
                  </Grid>{" "}
                  <Grid item>
                    <PrivacyPolicyCheckbox {...privacyCheckboxProps} />
                  </Grid>
                  <Grid item>
                    <Button
                      className={styles.modalStyling}
                      onClick={() => {
                        handleSubmit("send");
                      }}
                      color="primary"
                      variant="contained"
                      name="submitButton"
                      value="send"
                      type="button"
                      disabled={
                        !privacyAcceptState || emailError || email === ""
                      }
                      style={
                        !privacyAcceptState || emailError || email === ""
                          ? { backgroundColor: "lightgray !important" } // Specify the desired disabled style here
                          : {}
                      }
                    >
                      {loading ? <CircularProgress size={24} /> : "Küldés"}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() => {
                        handleSubmit("cancel");
                      }}
                      color="error"
                      variant="contained"
                      name="submitButton"
                      value="cancel"
                      type="button"
                    >
                      Nem kérem
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        ) : (
          <>
            {!isPartialSaveInitialized ? (
              <>
                <h2>
                  A kérdőív elmentéséhez, kérjük adja meg az e-mail címét!
                </h2>

                <EmailField {...emailFieldProps} />
                <Grid>
                  <Grid item>
                    <ReCAPTCHA
                      sitekey={ENV.REACT_APP_RECAPTCHA_SITE_KEY}
                      ref={captchaRef}
                    />
                  </Grid>
                  <Grid item>
                    <PrivacyPolicyCheckbox {...privacyCheckboxProps} />
                  </Grid>
                  <Grid item>
                    <Button
                      className={styles.modalStyling}
                      onClick={() => {
                        handleSubmit("save");
                      }}
                      color="primary"
                      variant="contained"
                      name="submitButton"
                      value="save"
                      type="button"
                      disabled={
                        !privacyAcceptState || emailError || email === ""
                      }
                      style={
                        !privacyAcceptState || emailError || email === ""
                          ? { backgroundColor: "lightgray !important" } // Specify the desired disabled style here //FIXME fix the disabled color change
                          : {}
                      }
                    >
                      Mentés
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <div>
                  <TaskAltIcon color="success" />
                  <p>Sikeresen mentve!</p>
                </div>
              </>
            )}
          </>
        )}
      </Modal>
    </>
  );
}
