import React, {useEffect} from "react";
import styles from "../styles/Contact.module.css";
import logo from "../assets/images/logo.svg";
import {scrollWindowToTop} from "../components/utilities/Utils";
// import LinkedIn from "@mui/icons-material/LinkedIn";
// import Facebook from "@mui/icons-material/Facebook";
// import YouTube from "@mui/icons-material/YouTube";

const Contact = () => {
    useEffect(() => {
        scrollWindowToTop()
    }, []);
    return (
        <div className={styles.main}>
            <section className={styles.headerSection}>
                <h1 className={styles.header_Title}>Ezek vagyunk mi</h1>
                <h3 className={styles.header_SubTitle} style={{marginBottom: "6rem"}}>
                    Az önkiértékelő audit alkalmazást a Datatornic Kft. fejlesztette, külső
                    szakértő bevonásával. Ismerjen meg minket!
                </h3>
            </section>
            <section className={styles.description}>
                <h2 className={styles.descriptionTitle}>Datatronic</h2>
                <p>
                    A Datatronic Kft. azzal a céllal alakult meg 2002-ben, hogy
                    fejlesztőink addigi tapasztalatait felhasználva, elsősorban az
                    informatika területén magas szintű szolgáltatásokat nyújtson, hazai és
                    külföldi ügyfelei részére.
                </p>
                <p style={{margin: "1rem 0"}}>
                    Sokrétű informatikai szolgáltatásaink a követelmények és igények
                    egyértelmű felmérésén, valamint a projektre szabott stratégia
                    maradéktalan végrehajtásán alapulnak. Középpontjukban minden esetben
                    az ügyfelek döntéseinek alapos előkészítése, valamint a saját szellemi
                    termékekkel kapcsolatos munka megszervezése és levezénylése áll.
                </p>
                <p>A cég fő tevékenységi körei: </p>
                <ul>
                    <li className={styles.listItem}>
                        infrastruktúra bérleti szolgáltatás saját adatközpontokból
                        kiszolgálva
                    </li>
                    <li className={styles.listItem}>teljes körű rendszerüzemeltetés</li>
                    <li className={styles.listItem}>
                        informatikai tanácsadás és szoftverfejlesztés, különös tekintettel
                        az <b>Oracle</b> termékeire
                    </li>
                    <li className={styles.listItem}>
                        felhő alapú technológiák és infrastruktúrák üzemeltetése és
                        fejlesztése
                    </li>
                </ul>
                <p style={{margin: "1rem 0"}}>
                    A rendszeres jó teljesítmény eredményeként a Datatronic Kft. rövid
                    időn belül ismertté vált a hazai és a multinacionális cégek körében
                    egyaránt. A gyors fejlődés és a versenyképesség növelése
                    elkerülhetetlenné tette a dolgozói létszám növelését és egy hatékonyan
                    működő cégstruktúra megteremtését és felépítését, ISO minősítések
                    bevezetését. A cég legfontosabb célja a megbízók elégedettségének
                    biztosítása, ennek érdekében az informatikai iparág legmodernebb
                    szoftvertechnológiáival dolgozik.
                </p>
                <p>
                    A Datatronic Kft már több, mint 20 éve dolgozik az Oracle termékeivel
                    és ötvözve azok gyors fejlődését és innovatív technológiáit, a
                    Datatronic mély iparági és rendszer-integrációs szakértelmével,
                    folyamatosan a legmagasabb értéket szállítja ügyfeleinek.
                </p>
                <p style={{margin: "1rem 0"}}>
                    Cégünk és tanácsadóink a legnagyobb projekteket támogatják
                    Magyarország és Európa szerte, így a nagyiparból ismert és bevált
                    technológiákat és megoldásokat tesszük elérhetővé a legkisebb
                    ügyfeleknek is, valamint cégünk által a legkompetensebb tanácsadók és
                    az iparág legjobb szakértői támogatják ügyfeleink kiemelkedő
                    elégedettségét.
                </p>
                <p>További információk:</p>
                <div className={styles.descSummary}>
                    <a href="https://datatronic.hu" target="_blank" rel="noreferrer">
                        <img
                            src={logo}
                            width={226}
                            height={60}
                            alt="datatronic-logo-navbar"
                        />
                    </a>
                    <a
                        style={{color: "red !important"}}
                        href="https://www.linkedin.com/company/datatronic-hu"
                    >
                        {/* <LinkedIn className={styles.linkedIcon} /> */}
                        Linkedin
                    </a>
                    <a href="https://www.facebook.com/datatronic.hu">
                        {/* <Facebook className={styles.faceIcon} /> */}
                        Facebook
                    </a>
                    <a href="https://www.youtube.com/@datatronickft.2048">
                        {/* <YouTube className={styles.youtubeIcon} /> */}
                        Youtube
                    </a>
                </div>

                <div className={styles.descriptionContainer} style={{marginTop: "2rem"}}>
                    <h2 style={{textAlign: "center"}}>Szakértők</h2>
                    <section className={styles.expertSection}>
                        <div className={styles.expert}>
                            <p style={{textAlign: "center"}}>
                                Ipar 4.0 kiértékelő audit alkalmazás külső szakértője:
                            </p>
                            <h3 style={{textAlign: "center"}}>Klem Krisztián</h3>
                            <p style={{textAlign: "center"}}>
                                vezető tanácsadó / tulajdonos
                            </p>
                            <p style={{textAlign: "center"}}>
                                ICariba RTS Hungary Consulting
                            </p>
                            <p
                                style={{
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <b>M: </b>{" "}
                                <a
                                    style={{margin: "0 0.5rem"}}
                                    href="mailto:krisztian.klem@leanbox.hu"
                                >
                                    krisztian.klem@leanbox.hu
                                </a>
                            </p>

                            <p style={{textAlign: "center"}}>
                                <b>T:</b> +36 30 990 5837
                            </p>
                            <p
                                style={{
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <b>W:</b>{" "}
                                <a style={{margin: "0 0.5rem"}} href="https://www.cariba.hu/">
                                    www.cariba.hu
                                </a>
                                <a href="https://www.leanbox.hu/">www.leanbox.hu</a>
                            </p>
                        </div>
                        <div className={styles.expert}>
                            <p style={{textAlign: "center"}}>
                                A projekt belső szakmai koordinátora és a projekt vezetője:
                            </p>
                            <h3 style={{textAlign: "center"}}>Ruzsinszki Viktor</h3>
                            <p style={{textAlign: "center"}}>
                                Ügyvezető igazgató
                            </p>
                            <p
                                style={{
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <b>M: </b>{" "}
                                <a
                                    style={{margin: "0 0.5rem"}}
                                    href="mailto:viktor.ruzsinszki@datatronic.hu"
                                >
                                    viktor.ruzsinszki@datatronic.hu
                                </a>
                            </p>

                            <p style={{textAlign: "center"}}>
                                <b>T:</b> +36 70 313 5599
                            </p>
                            <p
                                style={{
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <b>W:</b>{" "}
                                <a
                                    style={{margin: "0 0.5rem"}}
                                    href="https://www.datatronic.hu/"
                                >
                                    www.datatronic.hu
                                </a>
                            </p>
                        </div>
                    </section>
                </div>
            </section>
        </div>
    );
};

export default Contact;
