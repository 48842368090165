import {Alert, AlertTitle} from "@mui/material";

const SurveyLoadErrorMessage = () => {
    return (
        <>
            <Alert severity="warning">
                <AlertTitle>Hiba</AlertTitle>
                Sajnáljuk, nem sikerült betölteni a felmérést. <strong>Kérjük próbálja újra később!</strong>
            </Alert>
        </>
    );
};
export default SurveyLoadErrorMessage;

