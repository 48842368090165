import React, {useEffect, useState} from "react";
import styles from "../styles/NavBar.module.css";
import {Button, Drawer, IconButton, List} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import img from "../assets/images/DTLogoWhite.svg";
import imgD from "../assets/images/DTLogoMobile.svg";
import infoblokk from "../assets/images/NKFIA_infoblokk.jpg";
import {useNavigate} from "react-router";

const MENU_LIST = [
    {text: "Főoldal", href: "/"},
    {text: "Kérdőív", href: "/survey"},
    {text: "Kapcsolat", href: "/contact"},
    {text: "NIS2? - Mi segítünk", href: "https://datatronic.hu/nis2/"},
];


const NavBar = () => {
    const [isOpen, setOpen] = useState(false);

    const navigate = useNavigate();

    const useDeviceSize = () => {
        const [width, setWidth] = useState(0);

        const handleWindowResize = () => {
            setWidth(window.innerWidth);
        };

        useEffect(() => {
            // component is mounted and window is available
            handleWindowResize();
            window.addEventListener("resize", handleWindowResize);
            // unsubscribe from the event on component unmount
            return () => window.removeEventListener("resize", handleWindowResize);
        }, []);
        return width;
    };

    const toggleDrawer = () => {
        setOpen(!isOpen);
    };

    const routeChange = (route) => {
        if (route.startsWith("http")) {
            window.open(route, "_blank");
        } else {
            navigate(route);
            toggleDrawer();
        }
    };



    return (
        <div className={styles.main}>
            {useDeviceSize() < 768 ? (
                <div className={styles.drawerContainer}>
                    <div>
                        <a
                            href="https://datatronic.hu"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={imgD}
                                width={70}
                                height={60}
                                alt="datatronic-logo-navbar"
                                loading="lazy"
                            />
                        </a>
                    </div>
                    <img
                      style={{ cursor: "pointer", marginTop: '0.45rem' }}
                      onClick={() => {
                        routeChange("/info");
                      }}
                      src={infoblokk}
                      height={40}
                      alt="datatronic-logo-navbar"
                      loading="lazy"
                    />
                    <div>
                        <IconButton
                            className={styles.drawerButton}
                            onClick={() => {
                                toggleDrawer();
                            }}
                        >
                            <MenuIcon className={styles.largeIcon}/>
                        </IconButton>
                    </div>
                    <Drawer
                        anchor="top"
                        open={isOpen}
                        variant="temporary"
                        hideBackdrop={false}
                        onClose={() => {
                            toggleDrawer();
                        }}

                    >
                        <List className={styles.drawerList}>
                            {MENU_LIST.map((menu, idx) => (
                                <Button
                                    className={`${styles.DrawerButtonStyle} ${idx === MENU_LIST.length - 1 ? styles.HighlightedMenuItem : ''}`}
                                    key={idx}
                                    onClick={() => {
                                        routeChange(menu.href);
                                    }}
                                >
                                    {menu.text}
                                </Button>
                            ))}
                        </List>
                    </Drawer>
                </div>
            ) : (
                <div className={styles.drawerContainer}>
                    <div>
                        <a
                            href="https://datatronic.hu"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={img}
                                width={266}
                                height={60}
                                alt="datatronic-logo-navbar"
                            />
                        </a>
                    </div>
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        routeChange("/info");
                      }}
                      src={infoblokk}
                      height={55}
                      alt="datatronic-logo-navbar"
                      loading="lazy"
                    />
                    <div className={styles.listContainer}>
                        <List>
                            {MENU_LIST.map((menu, idx) => (
                                <Button
                                    className={`${styles.buttonStyle} ${idx === MENU_LIST.length - 1 ? styles.HighlightedMenuItem : ''}`}
                                    key={idx}
                                    onClick={() => {
                                        routeChange(menu.href);
                                    }}
                                >
                                    {menu.text}
                                </Button>
                            ))}
                        </List>
                    </div>
                </div>
            )}
        </div>
    );
};

export default NavBar;
