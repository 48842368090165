import CookieConsent, {
  getCookieConsentValue
} from "react-cookie-consent";

import "./App.css";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Survey from "./pages/Survey";
import NoInitSurvey from "./pages/NoInitSurvey";
import ContinueSurvey from "./pages/ContinueSurvey";
import Contact from "./pages/Contact";
import "react-responsive-modal/styles.css";
import "./styles/common.css";
import Info from "./pages/Info";
import SurveyModal from "./components/SurveyModal";
import { useEffect, useState } from "react";
import { setSessionStoredEmail } from "./components/utilities/Utils";
import {initGA} from "./components/utilities/GoogleAnalyticsUtility";
import {Cookies} from "react-cookie-consent";

function App() {
  const [open, setOpen] = useState(false);
  const [partialSaveState, setPartialSaveState] = useState(false);
  const [modalSubmitState, setModalSubmitState] = useState(false); //isPartialModalSubmitted
  const [typeOfSubmit, setTypeOfSubmit] = useState(""); // typeOfSubmit
  const [sessionFinished, setSessionFinished] = useState(false); // setSessionFinished
  const [modalMode, setModalMode] = useState(); //isCompleteModalNeeded
  const [email, setEmail] = useState("");
  const [privacyAcceptState, setPrivacyAcceptSate] = useState(false);
  const [surveyLoadError, setSurveyLoadError] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleAcceptCookie = () => {
    //console.log("Cookies Accepted")
    //console.log(process.env.REACT_APP_GOOGLE_ANALYTICS_ID)
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }
  };

  const handleDeclineCookie = () => {
    //console.log("Cookies Declined")
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);

  useEffect(() => {
    //// // console.log("email has changed: " + email)
    setSessionStoredEmail(email);
  }, [email]);

  useEffect(() => {
    //// // console.log("modalSubmitState has changed: " + modalSubmitState)
  }, [modalSubmitState]);

  const modalProps = {
    open,
    setOpen,
    isCompleteModalNeeded: modalMode,
    isPartialSaveInitialized: partialSaveState,
    email,
    setEmail,
    setModalSubmitState,
    setTypeOfSubmit,
    privacyAcceptState,
    setPrivacyAcceptSate,
    setCaptchaToken,
    loading,
    setLoading   
  };
  const surveyProps = {
    setOpen,
    setModalMode,
    email,
    setEmail,
    setPartialInit: setPartialSaveState,
    modalSubmitState,
    setModalSubmitState,
    typeOfSubmit,
    sessionFinished,
    setSessionFinished,
    surveyLoadError,
    setSurveyLoadError,
    captchaToken,
    setCaptchaToken,
    setLoading
  };

  return (
    <>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home surveyLoadError={surveyLoadError}/>} />
        <Route path="/survey" element={<Survey {...surveyProps} />} />
        <Route
          path="/survey/:id"
          element={<ContinueSurvey {...surveyProps} />}
        />
        <Route path="/noinit-survey" element={<NoInitSurvey {...surveyProps} />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/info" element={<Info />} />
      </Routes>
      <SurveyModal {...modalProps} />
      <Footer />
      <CookieConsent
          enableDeclineButton
          onAccept={handleAcceptCookie}
          onDecline={handleDeclineCookie}
          buttonText={"Elfogadom"}
          declineButtonText={"Nem járulok hozzá"}
          debug={(process.env.NODE_ENV !== "production")}
          style={{ background: "#004070", opacity: "95%"}}
          declineButtonStyle={{ fontWeight: "bold", background: "#c2002d", borderRadius: "3px"}}
          buttonStyle={{ fontWeight: "bold", background: "rgba(61,255,71,0.75)", borderRadius: "3px"}}
      >
        Ez a weboldal sütiket (cookies) használ a felhsználói élmény fejlesztéséhez.
      </CookieConsent>
    </>
  );
}

export default App;
