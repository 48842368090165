import InitSurvey from "../components/survey-components/InitSurvey"
import React, {useEffect} from "react";
import {scrollWindowToTop} from "../components/utilities/Utils";

export default function Survey({
                                   setOpen,
                                   setModalMode,
                                   email,
                                   setEmail,
                                   setPartialInit,
                                   modalSubmitState,
                                   setModalSubmitState,
                                   typeOfSubmit,
                                   sessionFinished,
                                   setSessionFinished,
                                   surveyLoadError,
                                   setSurveyLoadError,
                                   captchaToken,
                                   setLoading
                               }) {
    useEffect(() => {
        scrollWindowToTop()
    }, []);
    return (
        <>
            <InitSurvey setOpen={setOpen} setModalMode={setModalMode} email={email} setEmail={setEmail}
                        setPartialInit={setPartialInit}
                        modalSubmitState={modalSubmitState} setModalSubmitState={setModalSubmitState}
                        typeOfSubmit={typeOfSubmit} sessionFinished={sessionFinished}
                        setSessionFinished={setSessionFinished} surveyLoadError={surveyLoadError} setSurveyLoadError={setSurveyLoadError} captchaToken={captchaToken} setLoading={setLoading}/>
        </>
    )
}