import axios from "axios";
import ENV from "../../env";
import { surveyAccs, surveyId } from "./Utils";

export const api = async ({ baseUrl, token }) => {
  const instance = axios.create({
    baseURL: baseUrl   
  });

  instance.interceptors.request.use((config) => {    
      config.headers['g-recaptcha-response'] = token;
 
    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      return response.data; // return only the response data
    },
    function (error) {
      if (error?.response?.status === 401) {
        //AuthService.login();
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

export const fetchSurvey = async () => {
  try {
    const response = await axios.get(
      `${ENV.REACT_APP_API_URL}/survey-schemas/latest`
    );

    // console.log("fetchSurvey:: " + response.data.content);
    return response.data.content;
  } catch (error) {
    // console.log(`ERROR__fetchSurvey:: ${error}`);
    return false;
  }
};
export const fetchSurveyDataFromSave = async (paramID, accessKey) => {
  try {
    const response = await axios.get(
      `${ENV.REACT_APP_API_URL}/survey-schemas/latest/survey-results/${paramID}?accessKey=${accessKey}`
    );

    // console.log("fetchSurvey:: " + response.data.content);
    return response.data;
  } catch (error) {
    // console.log(`ERROR__fetchSurvey:: ${error}`);
  }
};

export const sendResult = async (
  captchaToken,
  survey_result,
  email,
  finish = true, isUserCompleted
) => {
  try {
    const response = await axios.post(
      `${ENV.REACT_APP_API_URL}/survey-schemas/latest/survey-results`,
      {
        content: JSON.stringify(survey_result),
        email: email,
        isUserCompleted: isUserCompleted
      },
      { headers: { "g-recaptcha-response": captchaToken } }
    );
    // console.log("sendResult:: " + response.status + " :: " + response.data);
    if (!finish) {
      // console.log("ACCS::" + response.data.accessKey);
      // console.log("ID::" + response.data.id);
      sessionStorage.setItem(surveyId, response.data.id);
      sessionStorage.setItem(surveyAccs, response.data.accessKey);
    }
    return response.data;
  } catch (error) {
    // console.log(`WERR__sendResult:: ${error}`);
  }
};

export const patchCompletedFlag = async (surveyID, accessKey, captchaToken) => {
  try {  
    await (await api({baseUrl: `${ENV.REACT_APP_API_URL}/survey-schemas/latest/survey-results/${surveyID}/complete?accessKey=${accessKey}`, token: captchaToken})).patch()
    // await axios.patch(
    //   `${ENV.REACT_APP_API_URL}/survey-schemas/latest/survey-results/${surveyID}/complete?accessKey=${accessKey}`,
    //   {
    //    headers:{'g-recaptcha-re'}
    //   }
    // );
     // console.log("CompletedFlagPatch:: COMPLETE ->" + JSON.stringify(response));
  } catch (error) {
    // console.log(`ERROR__CompletedFlagPatch:: ${error}`);
  }
};

export const patchSurveyConent = async (content, accessKey, resultId) => {
  try {
    await axios.patch(
      `${ENV.REACT_APP_API_URL}/survey-schemas/latest/survey-results/${resultId}/content?accessKey=${accessKey}`,
      {
        content: JSON.stringify(content),
      }
    );
    // console.log("ContentPatch:: COMPLETE ->" + response);
  } catch (error) {
    // console.log(`ERROR__ContentPatch:: ${error}`);
  }
};
